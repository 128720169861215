/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('select').foundationSelect().val("");

        $(document).foundation({
          topbar : {
            // sticky_class : 'sticky',
            custom_back_text: false,
            back_text: 'test',
            is_hover: true,
            mobile_show_parent_link: false
          },
          equalizer : {
            // Specify if Equalizer should make elements equal height once they become stacked.
            equalize_on_stack: true,
          }          
        }); // Foundation JavaScript

        var searchBtn = $('#search-btn'),
            searchBtnMobile = $('.search-mobile'),
            closeSearchBtn = $('#close-search-btn');
            closeSearchBtnMobile = $('#close-search-btn-mobile');

        $(searchBtn).on('click', function(event) {
          event.preventDefault();
            $('.search-form').show().css({right: $('.search-form').offset().right}).animate({
                right: "0"
            }, 300, function() {
                $(this).find('input[type=text]').focus();
            });
        });        

        $(searchBtnMobile).on('click', function(event) {
          event.preventDefault();
            $('.search-form-mobile').addClass('open');
            $('.search-form-mobile input[type=text]').focus();
        });

        $(closeSearchBtnMobile).on('click', function(event) {
          event.preventDefault();
            $('.search-form-mobile').removeClass('open');
        });              
          
        $(closeSearchBtn).on('click', function(event) {
            event.preventDefault();
            $('.search-form').css({right: $('.search-form').offset().right}).animate({
                right: "-100%"
            }, 300, function() {
                $('.search-form').hide();
            });
        });

        $(document).on("click", ".share-link", function (event) {
            event.preventDefault();
            var url = $(this).attr('data-url');
            window.open( url, "myWindow", "status = 1, height = 500, width = 600, resizable = 0" );
        });

        $('.top-bar-section .has-dropdown>a').on('click', function() {
            $('.top-bar-section').addClass('in-dropdown');
        });
        
        $('.top-bar-section > ul  > .has-dropdown > .dropdown > .back').on('click', function() {
            $('.top-bar-section').removeClass('in-dropdown');
        });

        $(document).on("click", ".drop-custom", function (event) {
            event.preventDefault();
            $('.drop').slideToggle();
        });

        var popin = $("#subscribe-result");
        var errorAlready = popin.find(".register-already");
        var errorMail = popin.find(".register-error-email");    
        var successRegistered = popin.find(".register-success");        
        ajaxMailChimpForm($("#subscribe-form"));
        
        // Turn the given MailChimp form into an ajax version of it.
        // If resultElement is given, the subscribe result is set as html to
        // that element.
        function ajaxMailChimpForm($form){
            $('.error').hide();
            $form.submit(function(e) {
                e.preventDefault();
                if (!isValidEmail($form)) {
                    errorMail.show();
                    popin.foundation('reveal', 'open');
                } else {
                    submitSubscribeForm($form);
                    $('.error').hide();
                }
            });
        }
        // Validate the email address in the form
        function isValidEmail($form) {
            // If email is empty, show error message.
            // contains just one @
            var email = $form.find("input[type='email']").val();
            if (!email || !email.length) {
                return false;
            } else if (email.indexOf("@") == -1) {
                return false;
            }
            return true;
        }
        // Submit the form with an ajax/jsonp request.
        // Based on http://stackoverflow.com/a/15120409/215821
        function submitSubscribeForm($form) {
            $.ajax({
                type: "GET",
                url: $form.attr("action"),
                data: $form.serialize(),
                cache: false,
                dataType: "jsonp",
                jsonp: "c", // trigger MailChimp to return a JSONP response
                contentType: "application/json; charset=utf-8",
                error: function(error){
                    // According to jquery docs, this is never called for cross-domain JSONP requests
                },
                success: function(data){
                    if (data.result != "success") {
                        // var message = data.msg || "Sorry. Unable to subscribe. Please try again later.";
                        // if (data.msg && data.msg.indexOf("already subscribed") >= 0) {
                        errorAlready.show();
                        popin.foundation('reveal', 'open');        
                        // }                    
                    } else {
                        $('.error').hide();
                        successRegistered.show();
                        popin.foundation('reveal', 'open'); 
                    }
                }
            });
        }

        if(langcode == 'fr'){
          var text = 'Votre message';
        }else if(langcode == 'en'){
          var text = 'Your message';
        }else{
          var text = 'Vuestro mensaje';
        }

        if($('.textarea-wrap > textarea').length){
          $('.textarea-wrap > textarea').attr('placeholder', text);
        }

        $(document).on('submitResponse.example', function( e, response ){
            if ( response.success == true ) {
                $('.intro').hide();      
                return true;
            }
        });        

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        var customAutoplaySpeed;
        if(duree && duree > 0){
          customAutoplaySpeed = duree*1000;
        }else{
          customAutoplaySpeed = 5000;
        }

        $('.header-slick').slick({
            arrows: true,
            dots: true,
            autoplay: true,
            pauseOnHover: true,
            autoplaySpeed: customAutoplaySpeed,
            adaptiveHeight: false,
            prevArrow: '<button type="button" class="slick-prev"><svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18"><title>slick-arrow</title><line x1="1" y1="9" x2="9" y2="1" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="1" y1="9" x2="9" y2="17" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="1" y1="9" x2="9" y2="1" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="1" y1="9" x2="9" y2="17" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18"><title>slick-arrow</title><line x1="9" y1="9" x2="1" y2="17" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="9" y1="9" x2="1" y2="1" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="9" y1="9" x2="1" y2="17" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="9" y1="9" x2="1" y2="1" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg></button>'
        });

        odometerOptions = { auto: false }; // Disables auto-initialization
        var el = document.querySelector('.number');
        var v = newNumberCP;
        var o = new Odometer({
            el: el,
            value: newNumberCP,
            format: '( ddd)',
            theme: 'minimal'
        });
        o.render();
        setInterval(function(){
            o.update(v+=3.09);
        }, 1400);

        function calcOffset(){
          var windowW = $(window).width();
          var container = $('.reseau .inside-container .row').width();
          var offset = (windowW-container)/2;
          $( ".reseau .compteur" ).css( "padding-left", offset);
        }

        calcOffset();
        
        $(window).resize(function() {
          calcOffset();
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_ligne': {
      init: function() {
        // JavaScript to be fired on the about us page
        var map;
        var customMarker = {
          path: 'M2.2,16.6c-1.4-2-2.2-4.1-2.2-6.3C0,4.6,4.6,0,10.3,0s10.3,4.6,10.3,10.3c0,2.2-0.7,4.3-2.2,6.3L10.3,28L2.2,16.6z',
          fillOpacity: 1,
          scale: 1,
          strokeWeight: 8,
          strokeOpacity: 0.4,
          zIndex: 1
        };  
        var fillOptions = {geodesic: false, strokeOpacity: 1, strokeWeight: 5, clickable: true, editable: false, zIndex: 1000}; 
        
        /* Déclaration de l'objet qui définira les limites de la map */ 
        var bounds = new google.maps.LatLngBounds();

        function initMap() {

          customMarker.anchor = new google.maps.Point(10.5, 29);

          map = new google.maps.Map(document.getElementById('map'), {
            // center: {lat: 48.20222, lng: 3.19189},
            // minZoom: 6,
            // maxZoom: 6,
            // zoom: 6,
            streetViewControl: false,
            MapTypeId: google.maps.MapTypeId.ROADMAP,
            zoomControl: true,
            scrollwheel: false,
            panControl: false,
            disableDoubleClickZoom: true,
            draggable: true
          });    

          for (var i = 0; i < dataMaps.length; i++) {
            var polyline = dataMaps[i].polyline;
            var color = dataMaps[i].color;
            var isLine = dataMaps[i].isLine;

            if(dataMaps[i].markers != null){
              for (var j = 0; j < dataMaps[i].markers.marker.length; j++) {
                fillOptions.strokeColor = color;
                customMarker.fillColor = color;
                customMarker.strokeColor = color;
                // console.log(dataMaps[i].markers.marker[j]);

                marker = new google.maps.Marker({
                    position: dataMaps[i].markers.marker[j],
                    icon: customMarker,
                    zIndex: 1
                });    

                bounds.extend(new google.maps.LatLng(dataMaps[i].markers.marker[j]));

                marker.setMap(map);
              }
            }

            if(polyline){
              if(isLine == 1){
                fillOptions.strokeWeight = 5;
              }else{
                fillOptions.strokeWeight = 3;
              }
              fillOptions.path = google.maps.geometry.encoding.decodePath(polyline);
              var flightPath = new google.maps.Polyline(fillOptions);
              flightPath.setMap(map);

              if(isLine != 1){
                google.maps.event.addListener(flightPath, 'mouseover', function() {
                      this.setOptions({
                          strokeWeight : 7
                      });
                });
                google.maps.event.addListener(flightPath, 'mouseout', function() {
                  this.setOptions({
                      strokeWeight : 3
                  });   
                });  
                google.maps.event.addListener(flightPath, 'click', (function(polyline, i) {
                  return function() {
                    link = dataMaps[i].link;
                    window.location.href = link;         
                  }
                })(polyline, i));
              }                         
            }
          }

          /* Ici, on ajuste le zoom de la map en fonction des limites  */ 
          map.fitBounds(bounds);
          // myFitBounds(map, bounds);

        }

        google.maps.event.addDomListener(window, 'load', initMap);

        google.maps.event.addDomListener(window, 'resize', function() {
          map.fitBounds(bounds);
          // myFitBounds(map, bounds);
        });        

        // function myFitBounds(myMap, bounds) {
        //     myMap.fitBounds(bounds); // calling fitBounds() here to center the map for the bounds

        //     var overlayHelper = new google.maps.OverlayView();
        //     overlayHelper.draw = function () {
        //         if (!this.ready) {
        //             var extraZoom = getExtraZoom(this.getProjection(), bounds, myMap.getBounds());
        //             if (extraZoom > 0) {
        //                 myMap.setZoom(myMap.getZoom() + extraZoom);
        //             }
        //             this.ready = true;
        //             google.maps.event.trigger(this, 'ready');
        //         }
        //     };
        //     overlayHelper.setMap(myMap);
        // }

        // function getExtraZoom(projection, expectedBounds, actualBounds) {

        //     // in: LatLngBounds bounds -> out: height and width as a Point
        //     function getSizeInPixels(bounds) {
        //         var sw = projection.fromLatLngToContainerPixel(bounds.getSouthWest());
        //         var ne = projection.fromLatLngToContainerPixel(bounds.getNorthEast());
        //         return new google.maps.Point(Math.abs(sw.y - ne.y), Math.abs(sw.x - ne.x));
        //     }

        //     var expectedSize = getSizeInPixels(expectedBounds),
        //         actualSize = getSizeInPixels(actualBounds);

        //     if (Math.floor(expectedSize.x) == 0 || Math.floor(expectedSize.y) == 0) {
        //         return 0;
        //     }

        //     var qx = actualSize.x / expectedSize.x;
        //     var qy = actualSize.y / expectedSize.y;
        //     var min = Math.min(qx, qy);

        //     if (min < 1) {
        //         return 0;
        //     }

        //     return Math.floor(Math.log(min) / Math.LN2 /* = log2(min) */);
        // }


      }
    },
    'page_template_template_carto': {
      init: function() {
        // JavaScript to be fired on the about us page

        // var dataMaps = dataMaps;
        var map;
        var customMarker = {
          path: 'M2.2,16.6c-1.4-2-2.2-4.1-2.2-6.3C0,4.6,4.6,0,10.3,0s10.3,4.6,10.3,10.3c0,2.2-0.7,4.3-2.2,6.3L10.3,28L2.2,16.6z',
          fillOpacity: 1,
          scale: 1,
          strokeWeight: 8,
          strokeOpacity: 0.4,
          zIndex: 1
        };  
        var fillOptions = {geodesic: false, strokeOpacity: 1, strokeWeight: 5, clickable: true, editable: false, zIndex: 1000}; 
        var bounds = new google.maps.LatLngBounds();

        function initMap() {

          customMarker.anchor = new google.maps.Point(10.5, 29);

          map = new google.maps.Map(document.getElementById('map'), {
            streetViewControl: false,
            MapTypeId: google.maps.MapTypeId.ROADMAP,
            zoomControl: true,
            scrollwheel: false,
            panControl: false,
            disableDoubleClickZoom: true,
            draggable: true
          });    

          for (var i = 0; i < dataMaps.length; i++) {
            var polyline = dataMaps[i].polyline;
            var color = dataMaps[i].color;

            if(dataMaps[i].markers != null){
              for (var j = 0; j < dataMaps[i].markers.marker.length; j++) {
                fillOptions.strokeColor = color;
                customMarker.fillColor = color;
                customMarker.strokeColor = color;
                // console.log(dataMaps[i].markers.marker[j]);
                marker = new google.maps.Marker({
                    position: dataMaps[i].markers.marker[j],
                    icon: customMarker,
                    zIndex: 1
                });    

                bounds.extend(new google.maps.LatLng(dataMaps[i].markers.marker[j]));                

                marker.setMap(map);
              }
            }

            if(polyline){
              fillOptions.path = google.maps.geometry.encoding.decodePath(polyline);
              var flightPath = new google.maps.Polyline(fillOptions);
              flightPath.setMap(map);   


              google.maps.event.addListener(flightPath, 'mouseover', function() {
                    this.setOptions({
                        strokeWeight : 7
                    });
              });

              google.maps.event.addListener(flightPath, 'mouseout', function() {
                    this.setOptions({
                        strokeWeight : 5
                    });
              });              


              google.maps.event.addListener(flightPath, 'click', (function(polyline, i) {
                return function() {
                  
                  link = dataMaps[i].link;
                  window.location.href = link;         
                }
              })(polyline, i));

            }
          }

          map.fitBounds(bounds);

        }

        google.maps.event.addDomListener(window, 'load', initMap);

        google.maps.event.addDomListener(window, 'resize', function() {
          map.fitBounds(bounds);
        }); 

      }
    },
    'single_client': {
      init: function() { 
        $(document).on('click', '.burger', function (event) {
          event.preventDefault();
          $(this).parent().find('ul').toggleClass('open');
        });

        $('.tabs-custom').on('toggled', function (event, tab) {
          var nametabActive = tab[0].innerText;
          $('.custom-menu .burger').find("span").html(nametabActive);
          $(this).parent().find('ul').removeClass('open');
        });

      }
    },
    'page_template_template_blog_php': {
      init: function() {
        var offset = 6; 
        $(document).on('click', '.refresh', function (event) {
            event.preventDefault();
            var id = "52";
            var end = $(this).attr('data-end');
            var currentlang = langcode;
            $(this).addClass('loading');

            var pageOffset = parseInt($(this).attr('data-offset')); 

            if(pageOffset > offset) 
               offset = pageOffset;

            jQuery.post(
                ajaxurl,
                {
                  'action': 'load_more_article',
                  'id': id,
                  'offset': offset,
                  'lang': currentlang         
                },
                function(response) {
                  // console.log(response);
                  offset = parseInt(offset + 6);
                  // console.log(offset);
                  if(response.trim() === 'no'){
                    $('.refresh').hide();
                    $('.all-articles').append('<div class="small-12 columns post-block text-center"><p>'+end+'</p></div>');
                  }else{
                    $('.all-articles').append(response); 

                    var state = { 'offset': offset, 'data-id': id };
                    var title = '';
                    var url = '?c-page='+(offset / 6);

                    history.pushState(state, title, url);     

                  }
                  $('.refresh').removeClass('loading');

              
                }
            );
        });        
      }
    },
    'page_template_template_clients_php': {
      init: function() {
        var offset = 6; 
        $(document).on('click', '.refresh', function (event) {
            event.preventDefault();
            var id = $(this).attr('data-id');
            var end = $(this).attr('data-end');
            var currentlang = langcode;
            $(this).addClass('loading');

            var pageOffset = parseInt($(this).attr('data-offset')); 

            if(pageOffset > offset) 
               offset = pageOffset;

            jQuery.post(
                ajaxurl,
                {
                  'action': 'load_more_article_clients',
                  'id': id,
                  'offset': offset,
                  'lang': currentlang                
                },
                function(response) {
                  // console.log(response);
                  offset = parseInt(offset + 6);
                  if(response.trim() === 'no'){
                    $('.refresh').hide();
                    $('.accordion').after('<div class="small-12 columns post-block text-center"><p>'+end+'</p></div>');
                  }else{
                    $('.accordion').append(response); 

                    var state = { 'offset': offset, 'data-id': id };
                    var title = '';
                    var url = '?c-page='+(offset / 6);

                    history.pushState(state, title, url);  

                  }
                  $('.refresh').removeClass('loading'); 

                 
                }
            );
        });        
      }
    },  

    'page_template_template_chargeurs_php': {
      init: function() {
        var offset = 6; 
        $(document).on('click', '.refresh', function (event) {
            event.preventDefault();
            var id = $(this).attr('data-id');
            var end = $(this).attr('data-end');
            var currentlang = langcode;
            $(this).addClass('loading');

            var pageOffset = parseInt($(this).attr('data-offset')); 

            if(pageOffset > offset) 
               offset = pageOffset;

            jQuery.post(
                ajaxurl,
                {
                  'action': 'load_more_article_chargeurs',
                  'id': id,
                  'offset': offset,
                  'lang': currentlang                
                },
                function(response) {
                  // console.log(response);
                  offset = parseInt(offset + 6);
                  if(response.trim() === 'no'){
                    $('.refresh').hide();
                    $('.accordion').after('<div class="small-12 columns post-block text-center"><p>'+end+'</p></div>');
                  }else{
                    $('.accordion').append(response); 

                    var state = { 'offset': offset, 'data-id': id };
                    var title = '';
                    var url = '?c-page='+(offset / 6);

                    history.pushState(state, title, url);  
                  }
                  $('.refresh').removeClass('loading'); 


                 
                }
            );
        });        
      }
    },  
 
    'page_template_template_histoire_php': {
      init: function() {
          var timelineBlocks = $('.cd-timeline-block'),
            offset = 0.8;

          //hide timeline blocks which are outside the viewport
          hideBlocks(timelineBlocks, offset);

          //on scolling, show/animate timeline blocks when enter the viewport
          $(window).on('scroll', function(){
            (!window.requestAnimationFrame) 
              ? setTimeout(function(){ showBlocks(timelineBlocks, offset); }, 100)
              : window.requestAnimationFrame(function(){ showBlocks(timelineBlocks, offset); });
          });

          function hideBlocks(blocks, offset) {
            blocks.each(function(){
              ( $(this).offset().top > $(window).scrollTop()+$(window).height()*offset ) && $(this).find('.cd-timeline-content').addClass('is-hidden');
            });
          }

          function showBlocks(blocks, offset) {
            blocks.each(function(){
              ( $(this).offset().top <= $(window).scrollTop()+$(window).height()*offset) && $(this).find('.cd-timeline-content').removeClass('is-hidden').addClass('bounce-in');
            });
          }        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        // $('select').foundationSelect();
        // console.log('ok');
      }
    },
    'page_template_template_presse_php': {
      init: function() {
        var offset = 10; 
        $(document).on('click', '.refresh', function (event) {
            event.preventDefault();
            var id = $(this).attr('data-id');
            var end = $(this).attr('data-end');
            var currentlang = langcode;
            $(this).addClass('loading');

            var pageOffset = parseInt($(this).attr('data-offset')); 

            if(pageOffset > offset) 
               offset = pageOffset;

            jQuery.post(
                ajaxurl,
                {
                  'action': 'load_more_article_presses',
                  'id': id,
                  'offset': offset,
                  'lang': currentlang                
                },
                function(response) {
                  // console.log(response);
                  offset = parseInt(offset + 10);
                  if(response.trim() === 'no'){
                    $('.refresh').hide();
                    $('.all-presses').append('<div class="small-12 columns post-block text-center"><p>'+end+'</p></div>');
                  }else{
                    $('.all-presses').append(response);
                    var state = { 'offset': offset, 'data-id': id };
                    var title = '';
                    var url = '?c-page='+(offset / 6);

                    history.pushState(state, title, url); 

                  }
                  $('.refresh').removeClass('loading'); 

                  
                }
            );
        });        
        
        if(window.location.hash){
          var hash = window.location.hash.substring(1);         
          $('html, body').animate( { scrollTop: $('#'+hash).offset().top - 150 }, 750 ); // Go
          return false;
        }

      }
    },    
    'page_template_template_terminaux_php': {
      init: function() {
        if(window.location.hash){
          var hash = window.location.hash.substring(1);    
          // console.log(hash);
          $('html, body').animate( { scrollTop: $('#client-'+hash).offset().top - 150 }, 750 ); // Go
          return false;
        }
      }
    },    
    'mentions_legales': {
      init: function() {

        if(window.location.hash){
          var hash = window.location.hash.substring(1);
          console.log(hash);
          $('html,body').animate({
            scrollTop: $('#' + hash).offset().top - 130 
            }, 750);
          return false;          
        }

        $('a.anchor').on("click", function(event) {
          event.preventDefault();
            var target = $(this.hash);
            target = target.length ? target : $('#' + this.hash.slice(1));
            console.log(target);
            if (target.length) {
              $('html,body').animate({
                scrollTop: target.offset().top - 130 
                }, 750);
              return false;
            }
        });
      }
    },
    'page_template_template_faq_php': {
      init: function() {
         $(".accordion").on("click", "li", function (event){

            if($(this).hasClass('active'))
              $(".accordion li div.content:visible").slideToggle("normal");
            else
            {
              $(".accordion li div.content:visible").slideToggle("normal");
              $(this).find(".content").slideToggle("normal");
            }
        }); 
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
